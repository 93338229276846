import { type FC, useCallback, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useSession } from 'next-auth/react'
import { Clear, ExpandMore } from '@mui/icons-material'
import { sanitize } from 'isomorphic-dompurify'

import BaseDialog from '@/components/ui/baseComponents/BaseDialog'
import { useUserProvider } from '@/store/userProvider'
import { getBrowserInfo } from '@/helpers/browser'
import Row from '@/components/ui/layout/Row'
import useToggleState from '@/hooks/useToggleState'
import Link from '@/components/ui/Link'
import { STRINGS } from '@/constants'
import theme from '@/themes/comcTheme'

export const EnvironmentAlert: FC = () => {
  const { toggleState: feedbackModalState, toggleStateHandler: toggleFeedbackModalState } = useToggleState(false)
  const isSmallScreenSize = useMediaQuery(theme.breakpoints.down('md'))

  const {
    BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_0,
    BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_1,
    BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_2,
    LEGACY_COMC_SITE_LINK_TEXT,
  } = STRINGS.segments.environmentAlert

  const messages: Record<string, JSX.Element> = {
    development: <>{STRINGS.BETA_DEV_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE}</>,
    test: <>{STRINGS.BETA_TEST_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE}</>,
    production: isSmallScreenSize ? (
      <></>
    ) : (
      <>
        {BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_0}{' '}
        <Link href="https://www.comc.com/">{LEGACY_COMC_SITE_LINK_TEXT}</Link>{' '}
        {BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_1}
        <br />
        {BETA_PROD_WEBSITE_STATE_ENVIRONMENT_ALERT_MESSAGE_2}
      </>
    ),
  }

  return (
    <Alert variant="banner" color="attention" icon={false}>
      {messages[process.env.NEXT_PUBLIC_APP_ENV] || messages.development}
      <Button variant="text" onClick={() => toggleFeedbackModalState()}>
        {STRINGS.REPORT_FEEDBACK_ACTION}
      </Button>
      <FeedbackDialog modalState={feedbackModalState} toggleModalState={toggleFeedbackModalState} />
    </Alert>
  )
}

const FeedbackDialog: FC<{ modalState: boolean; toggleModalState: () => void }> = ({
  modalState,
  toggleModalState,
}) => {
  const recipient = process.env.NEXT_PUBLIC_FEEDBACK_EMAIL
  const { userSummary } = useUserProvider()
  const { data: session } = useSession()
  const [contactEmail, setContactEmail] = useState('')
  const [comment, setComment] = useState('')

  const makeMailToLink = (env: string, body: string) => {
    const protocol = 'mailto:'
    const subject = `[Bug Report][${env}] - `
    return `${protocol}${recipient}?subject=${subject}&body=${body}`
  }

  const generateEmailBody = useCallback(() => {
    const username = userSummary?.userName || session?.user?.name || 'Unauthenticated User'
    return `Username: ${username}<br>${
      contactEmail && `Contact Email: ${contactEmail}<br> `
    }Comment: ${comment}<br><br>Page URL: ${
      window.location.href
    }<br>Browser Info: <br>${getBrowserInfo()}<br>Page Dimensions(WxH): ${window.innerWidth}x${window.innerHeight}`
  }, [contactEmail, comment, userSummary, session])

  const onClose = () => {
    setComment('')
    setContactEmail('')
  }

  return (
    <BaseDialog open={modalState}>
      <DialogTitle id="feedback-form-dialog-title" textAlign="center" variant="h5">
        Report feedback
      </DialogTitle>
      <DialogContent sx={{ mx: 2 }}>
        <IconButton
          color="primary"
          sx={{ position: 'absolute', top: 4, right: 4 }}
          onClick={() => {
            toggleModalState()
            onClose()
          }}
        >
          <Clear fontSize="small" />
        </IconButton>
        <Stack spacing={2} mt={1}>
          <FormControl fullWidth={true}>
            <TextField
              fullWidth
              variant="outlined"
              color="primary"
              autoComplete="off"
              label="Your Email"
              value={contactEmail}
              onChange={(e) => {
                setContactEmail(e.target.value)
              }}
            />
          </FormControl>
          <FormControl fullWidth={true}>
            <TextField
              fullWidth
              required
              multiline
              rows={4}
              variant="outlined"
              color="primary"
              autoComplete="off"
              label="Your feedback"
              helperText={STRINGS.BETA_FEEDBACK_INSTRUCTIONS_LONG_MESSAGE}
              value={comment}
              onChange={(e) => {
                setComment(e.target.value)
              }}
            />
          </FormControl>

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Row spacing={1} alignItems="startn">
                <Typography whiteSpace="nowrap">Troubleshooting</Typography>
                <Typography variant="caption">
                  {STRINGS.BETA_FEEDBACK_TROUBLESHOOTING_PREFIX_MESSAGE}{' '}
                  <Link href={`mailto:${process.env.NEXT_PUBLIC_FEEDBACK_EMAIL}`}>
                    {process.env.NEXT_PUBLIC_FEEDBACK_EMAIL}
                  </Link>{' '}
                </Typography>
              </Row>
            </AccordionSummary>
            <AccordionDetails>
              <Typography dangerouslySetInnerHTML={{ __html: sanitize(generateEmailBody()) }}></Typography>
            </AccordionDetails>
          </Accordion>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        <Stack alignItems="baseline" spacing={1} justifyContent="end">
          <Row spacing={2}>
            <Button
              onClick={() => {
                toggleModalState()
                onClose()
              }}
            >
              Done
            </Button>
            <Button
              variant="contained"
              href={makeMailToLink(process.env.NEXT_PUBLIC_APP_ENV, generateEmailBody().replaceAll('<br>', `%0D`))}
            >
              Generate Email
            </Button>
          </Row>
        </Stack>
      </DialogActions>
    </BaseDialog>
  )
}
