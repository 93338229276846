import type { FundsSummary } from '@/interfaces/user/fundsSummaryAPI'
import type { WatchListResponse } from '@/interfaces/watchList/watchList'
import type { WatchlistApiRequest } from '@/interfaces/watchList/watchListApiRequest'
import type { PendingOfferResponse } from '@/interfaces/offer/pendingOffer'
import type { UserSummary } from '@/interfaces/user/summary'
import type { ContactInformation } from '@/interfaces/user/contactInformation'

import {
  createContext,
  type Dispatch,
  type PropsWithChildren,
  type SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react'
import { useSession } from 'next-auth/react'
import { QueryClient, useMutation } from '@tanstack/react-query'
import { useLDClient } from 'launchdarkly-react-client-sdk'

import { userQueryKeys } from '@/interfaces/user/userQueries'
import { type PatchParams, RequestMethods } from '@/interfaces/api/requestMethods'
import { DEFAULT_WATCHLIST_PARAMS } from '@/constants'
import { clientAPIRequest } from '@/helpers'
import useCreditSummary from '@/hooks/data/useCreditSummary'
import useUserSummary from '@/hooks/data/useUserSummary'
import useContactInformation from '@/hooks/data/useContactInformation'
import usePendingOffers from '@/hooks/data/usePendingOffers'
import useWatchlistItems from '@/hooks/data/useWatchlistItems'

const queryClient = new QueryClient()

type UserContext = {
  needsActivation?: boolean
  userSummary?: UserSummary
  isUserSummaryLoading: boolean
  fetchUserSummary: () => void
  contactInformation?: ContactInformation
  updateContactInformation: (newContactInfo: ContactInformation) => Promise<boolean>
  contactInformationSaving: boolean
  contactInformationSaved: boolean
  isUpdateContactInformationSuccess: boolean
  setIsUpdateContactInformationSuccess: Dispatch<SetStateAction<boolean>>
  fundsSummary: FundsSummary | undefined
  isFundsSummaryLoading: boolean
  isFundsSummaryError: boolean
  fetchUserFundsSummary: () => void
  watchList?: WatchListResponse
  isWatchListLoading: boolean
  isWatchListError: boolean
  fetchWatchList: () => void
  isWatching: (id: number) => boolean
  addRemoveOrUpdateWatchlistItemMutation: (payload: {
    itemId: number
    method: RequestMethods.PUT | RequestMethods.DELETE | RequestMethods.PATCH
    notes?: PatchParams[]
  }) => void
  isAddRemoveOrUpdateWatchlistItemMutationLoading: boolean
  isAddRemoveOrUpdateWatchlistItemMutationSuccess: boolean
  successfulAddToWatchlist?: boolean
  setSuccessfulAddToWatchlist: Dispatch<SetStateAction<boolean | undefined>>
  watchListPage: number
  setWatchListPage: Dispatch<SetStateAction<number>>
  watchlistQueryParams: WatchlistApiRequest | undefined
  setWatchlistQueryParams: Dispatch<SetStateAction<WatchlistApiRequest | undefined>>
  fetchPendingOffers: () => void
  isPendingOffersLoading: boolean
  pendingOffers?: PendingOfferResponse
  totalPendingOffers: number
}

const userContext = createContext<UserContext>({
  isUserSummaryLoading: true,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchUserSummary: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateContactInformation: () => Promise.resolve(false),
  contactInformationSaving: false,
  contactInformationSaved: false,
  isUpdateContactInformationSuccess: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setIsUpdateContactInformationSuccess: () => {},
  fundsSummary: undefined,
  isFundsSummaryLoading: false,
  isFundsSummaryError: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchUserFundsSummary: () => {},
  watchList: undefined,
  isWatchListLoading: false,
  isWatchListError: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchWatchList: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  isWatching: () => false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addRemoveOrUpdateWatchlistItemMutation: () => {},
  isAddRemoveOrUpdateWatchlistItemMutationLoading: false,
  isAddRemoveOrUpdateWatchlistItemMutationSuccess: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSuccessfulAddToWatchlist: () => {},
  watchListPage: 1,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setWatchListPage: () => {},
  watchlistQueryParams: DEFAULT_WATCHLIST_PARAMS,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setWatchlistQueryParams: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  fetchPendingOffers: () => {},
  isPendingOffersLoading: false,
  pendingOffers: [],
  totalPendingOffers: 0,
})

export default function UserProvider({ children }: PropsWithChildren): JSX.Element {
  const { data: session } = useSession()
  const [isUpdateContactInformationSuccess, setIsUpdateContactInformationSuccess] = useState(false)
  const [watchListPage, setWatchListPage] = useState(1)
  const [watchlistQueryParams, setWatchlistQueryParams] = useState<WatchlistApiRequest | undefined>(
    DEFAULT_WATCHLIST_PARAMS
  )
  const [successfulAddToWatchlist, setSuccessfulAddToWatchlist] = useState<boolean>()
  const {
    fundsSummary,
    isError: isFundsSummaryError,
    isLoading: isFundsSummaryLoading,
    refetch: fetchUserFundsSummary,
  } = useCreditSummary()
  const { userSummary, isUserSummaryLoading, needsActivation, refetch: fetchUserSummary } = useUserSummary()
  const { data: contactInformation, refetch: getContactInformation } = useContactInformation()
  const { pendingOffers, totalPendingOffers, fetchPendingOffers, isPendingOffersLoading } = usePendingOffers()
  const { isWatchListLoading, isWatchListError, watchList, fetchWatchList } = useWatchlistItems(watchlistQueryParams)
  const ldClient = useLDClient()

  useEffect(() => {
    if (!ldClient || !userSummary) return
    if (ldClient.getContext().name !== userSummary.userName) {
      ldClient.waitForInitialization().then(() => {
        if (!userSummary.userName) return
        ldClient.identify({
          anonymous: false,
          kind: 'user',
          key: userSummary.userName,
          name: userSummary.userName,
        })
      })
    }
  }, [userSummary, ldClient])

  const {
    mutateAsync: updateContactInformation,
    isPending: contactInformationSaving,
    isSuccess: contactInformationSaved,
  } = useMutation({
    mutationFn: (payload: ContactInformation) => {
      return clientAPIRequest<boolean, ContactInformation>(
        '/api/user/contactInformation',
        RequestMethods.PUT,
        payload,
        session
      )
    },
    onSuccess: () => {
      getContactInformation()
    },
  })

  const addRemoveOrUpdateWatchlistItem = useCallback(
    async (
      itemId: number,
      method: RequestMethods.PUT | RequestMethods.DELETE | RequestMethods.PATCH,
      notes?: PatchParams[]
    ) => {
      return clientAPIRequest<boolean, undefined | PatchParams[]>(
        `/api/user/watchlist/${itemId}`,
        method,
        notes,
        session
      )
    },
    [session]
  )

  const {
    mutate: addRemoveOrUpdateWatchlistItemMutation,
    isPending: isAddRemoveOrUpdateWatchlistItemMutationLoading,
    isSuccess: isAddRemoveOrUpdateWatchlistItemMutationSuccess,
  } = useMutation({
    mutationFn: (payload: {
      itemId: number
      method: RequestMethods.PUT | RequestMethods.DELETE | RequestMethods.PATCH
      notes?: PatchParams[]
    }) => addRemoveOrUpdateWatchlistItem(payload.itemId, payload.method, payload.notes),
    onMutate: async (payload: {
      itemId: number
      method: RequestMethods.PUT | RequestMethods.DELETE | RequestMethods.PATCH
    }) => {
      await queryClient.cancelQueries({ queryKey: [userQueryKeys.GetWatchList] })

      const previousWatchlistItems = queryClient.getQueryData<WatchListResponse>([userQueryKeys.GetWatchList])

      if (previousWatchlistItems) {
        if (payload.method === RequestMethods.PUT || payload.method === RequestMethods.PATCH) {
          fetchWatchList()
        } else {
          queryClient.setQueryData<WatchListResponse>([userQueryKeys.GetWatchList], {
            ...previousWatchlistItems,
            results: previousWatchlistItems.results.filter((item) => item.itemId !== payload.itemId),
          })
        }
      }

      return { previousWatchlistItems }
    },
    // eslint-disable-next-line n/handle-callback-err
    onError: (err, variables, context) => {
      if (context?.previousWatchlistItems) {
        queryClient.setQueryData<WatchListResponse>([userQueryKeys.GetWatchList], context.previousWatchlistItems)
      }
    },
    onSuccess: (_data, variables) => {
      setSuccessfulAddToWatchlist(variables.method === RequestMethods.PUT && _data)
      setWatchlistQueryParams(undefined)
      fetchWatchList()
        .then(() => {
          isWatching(variables.itemId)
        })
        .then(() => Promise.resolve())
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [userQueryKeys.GetWatchList] }).then(() => Promise.resolve())
    },
  })

  const isWatching = useCallback(
    (id: number) => {
      if (watchList && watchList.results.length) {
        return watchList.results.some(({ itemId }) => id === itemId)
      }
      return false
    },
    [watchList]
  )

  const userValue = useMemo(
    () => ({
      needsActivation,
      userSummary,
      isUserSummaryLoading,
      fetchUserSummary,
      contactInformation,
      updateContactInformation,
      contactInformationSaving,
      contactInformationSaved,
      isUpdateContactInformationSuccess,
      setIsUpdateContactInformationSuccess,
      fundsSummary,
      isFundsSummaryLoading,
      isFundsSummaryError,
      fetchUserFundsSummary,
      watchList,
      isWatchListLoading,
      isWatchListError,
      fetchWatchList,
      isWatching,
      addRemoveOrUpdateWatchlistItemMutation,
      isAddRemoveOrUpdateWatchlistItemMutationLoading,
      isAddRemoveOrUpdateWatchlistItemMutationSuccess,
      successfulAddToWatchlist,
      setSuccessfulAddToWatchlist,
      watchListPage,
      setWatchListPage,
      watchlistQueryParams,
      setWatchlistQueryParams,
      pendingOffers,
      fetchPendingOffers,
      isPendingOffersLoading,
      totalPendingOffers,
    }),
    [
      needsActivation,
      userSummary,
      isUserSummaryLoading,
      fetchUserSummary,
      contactInformation,
      updateContactInformation,
      contactInformationSaved,
      contactInformationSaving,
      isUpdateContactInformationSuccess,
      fundsSummary,
      isFundsSummaryLoading,
      isFundsSummaryError,
      fetchUserFundsSummary,
      watchList,
      isWatchListLoading,
      isWatchListError,
      fetchWatchList,
      isWatching,
      addRemoveOrUpdateWatchlistItemMutation,
      isAddRemoveOrUpdateWatchlistItemMutationLoading,
      isAddRemoveOrUpdateWatchlistItemMutationSuccess,
      successfulAddToWatchlist,
      setSuccessfulAddToWatchlist,
      watchListPage,
      watchlistQueryParams,
      pendingOffers,
      fetchPendingOffers,
      isPendingOffersLoading,
      totalPendingOffers,
    ]
  )

  return <userContext.Provider value={userValue}>{children}</userContext.Provider>
}

export const useUserProvider = (): UserContext => useContext(userContext)
