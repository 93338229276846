import type { ProfileMenuItem } from '@/interfaces/navigation/menuItem'

import { type FC, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import AccountCircle from '@mui/icons-material/AccountCircle'
import { useSession } from 'next-auth/react'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'
import { ChevronRight } from '@mui/icons-material'

import SignOutMenuItem from './SignOutMenuItem'
import { disableTooltipBasedOnLength, formatCurrency } from '@/helpers'
import { useUserProvider } from '@/store/userProvider'
import AvailableStoreCredit from '@/components/ui/user/AvailableStoreCredit'
import { addCreditHref, DASHBOARD_BASE_HREF, purchaseHistoryHref, sellersHubHref, STRINGS } from '@/constants'
import { useAnimation } from '@/hooks/useAnimation'
import DollarSignIcon from '@/components/icons/DollarSignIcon'

const ProfileMenu: FC = () => {
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null)
  const { data: session } = useSession()
  const { fundsSummary, userSummary, successfulAddToWatchlist, setSuccessfulAddToWatchlist } = useUserProvider()
  const profileIcon = useRef<HTMLButtonElement>()
  const { animateCSS } = useAnimation<HTMLButtonElement>()
  const isProfileMenuOpen = Boolean(profileMenuAnchorEl)
  const isFundsLong = !!(fundsSummary && fundsSummary.availableCredit.toString().length > 5)
  const isPendingDepositEnabled = Boolean(false)
  // we do not currently have an endpoint for pending deposit but this will be shown if/when we do

  const MENU_ITEMS: ProfileMenuItem[] = [
    {
      text: 'Dashboard',
      href: DASHBOARD_BASE_HREF,
    },
    {
      text: `Seller's Hub`,
      href: sellersHubHref,
    },
    {
      text: 'Profile',
      href: '/user/profile',
    },
  ]

  const handleProfileMenuClose = () => {
    setProfileMenuAnchorEl(null)
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setProfileMenuAnchorEl(event.currentTarget)
  }

  useEffect(() => {
    if (successfulAddToWatchlist) {
      setSuccessfulAddToWatchlist(undefined)
      if (profileIcon.current) {
        animateCSS(profileIcon.current, 'headShake')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfulAddToWatchlist, setSuccessfulAddToWatchlist])

  return (
    <>
      <Stack alignItems="center" justify-content="center" spacing={0.25}>
        <Tooltip title="Open Profile Menu">
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="profile-menu"
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
            data-test-id="profile-menu-button"
            ref={(e) => {
              if (e) {
                profileIcon.current = e
              }
            }}
            sx={{ p: 0.25 }}
          >
            <AccountCircle fontSize="large" />
          </IconButton>
        </Tooltip>

        <Tooltip
          title={userSummary?.userName || session?.user?.name}
          disableHoverListener={disableTooltipBasedOnLength(userSummary?.userName || session?.user?.name || '', 10)}
        >
          <Typography
            variant="caption1"
            color="textSecondary"
            textOverflow="ellipsis"
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 64,
            }}
          >
            {userSummary?.userName || session?.user?.name}
          </Typography>
        </Tooltip>
      </Stack>

      <Menu
        anchorEl={profileMenuAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        id="profile-menu"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isProfileMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <Stack
          justifyContent="left"
          alignItems="left"
          component="div"
          pt={1}
          px={4}
          pb={2}
          spacing={1}
          sx={{ width: '258px' }}
        >
          <Stack direction="row" alignItems="left">
            <AccountCircle sx={{ fontSize: 20 }} />
            <Typography sx={{ pl: 1, fontsize: '16px' }}>
              Hi<span>, {userSummary?.userName || session?.user?.name}!</span>
            </Typography>
          </Stack>

          <Stack justifyContent="left" alignItems="left" spacing={1}>
            <Tooltip title="Add Credit">
              <Button
                sx={{
                  maxHeight: 35,
                  minWidth: 171,
                  maxWidth: 193,
                  justifyContent: 'flex-start',
                  pl: isFundsLong ? 3 : 1,
                  py: 1,
                  width: 'min-content',
                }}
                variant="contained"
                color="brandGreen"
                href={addCreditHref()}
                startIcon={isFundsLong && <DollarSignIcon />}
              >
                <AvailableStoreCredit disableText={isFundsLong} />
              </Button>
            </Tooltip>
            {fundsSummary && isPendingDepositEnabled && (
              <Stack direction="row" alignItems="left" justifyContent="left" spacing={1 / 2}>
                <Typography variant="caption1" fontSize={14} color="text.secondary">
                  <HourglassEmptyIcon sx={{ color: 'brandGreen.main', fontSize: 14, mb: -1 / 5, ml: -1 / 3 }} />
                  {STRINGS.FUNDS_IN_PENDING_OFFERS_LABEL}
                </Typography>
                <Typography variant="caption1" color="brandGreen.main" fontWeight={500} fontSize={14}>
                  {formatCurrency(fundsSummary.creditBalance)}
                </Typography>
              </Stack>
            )}
            {fundsSummary && fundsSummary.pendingFunds > 0 && (
              <Link href={purchaseHistoryHref} underline="none" color="brandGreen.main" noWrap>
                <Stack direction="row" spacing={1 / 2} flexWrap="nowrap" minWidth="193px">
                  <Stack direction="row" alignItems="left" justifyContent="left">
                    <Typography variant="caption1" fontSize={14} color="text.secondary" sx={{ mr: 1 / 2 }}>
                      {STRINGS.LOCKED_IN_OFFERS_LABEL}
                    </Typography>
                    <Typography variant="caption1" color="brandGreen.main" fontWeight={500} fontSize={14}>
                      {formatCurrency(fundsSummary.pendingFunds)}
                    </Typography>
                  </Stack>
                  <Box width="200px">
                    <ChevronRight sx={{ fontWeight: 500, height: '14px', width: '14px', float: 'right' }} />
                  </Box>
                </Stack>
              </Link>
            )}
          </Stack>
        </Stack>

        <Divider />
        {MENU_ITEMS.map((item) => (
          <MenuItem
            sx={{ px: 4 }}
            key={item.text}
            component={item.href ? 'a' : 'li'}
            href={item.href && item.href}
            onClick={item.onClick && item.onClick}
          >
            <ListItemText>{item.text}</ListItemText>
          </MenuItem>
        ))}
        <Divider />
        <SignOutMenuItem />
      </Menu>
    </>
  )
}

export default ProfileMenu
